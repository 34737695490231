<template>
  <div
    class="auth-wrapper auth-v1 px-2"
  >
    <div class="auth-inner py-2">
      <b-card
        style="border-radius: 16px"
      >
        <b-link class="brand-logo">
          <logo style="width: 300px" />
        </b-link>

        <validation-observer
          ref="loginValidation"
          #default="{ invalid }"
        >
          <b-form
            v-if="!error"
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Senha</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Senha"
                rules="required|password"
                vid="Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge input-color"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="············"
                    @focus="error = ''"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>

                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Confirmar Senha</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Senha"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="cPassword"
                    :type="passwordFieldType2"
                    class="form-control-merge input-color"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="············"
                    @focus="error = ''"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon2"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>

                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- submit button -->
            <div
              v-if="error"
              class="text-danger text-center mb-1 mt-1"
            >
              {{ error }}
            </div>

            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="validationForm"
            >
              Alterar
            </b-button>
          </b-form>
          <p
            v-else
            class="text-danger"
          >
            {{ error }}
          </p>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {
    ValidationProvider,
    ValidationObserver,
} from 'vee-validate';
import {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BInputGroup,
    BInputGroupAppend,
} from 'bootstrap-vue';
import '@validations';
import {
    togglePasswordVisibility,
} from '@core/mixins/ui/forms';

import Logo from '@core/layouts/components/Logo.vue';

import AuthService from '@/services/osiris/AuthService';

export default {
    components: {
        Logo,
        BButton,
        BForm,
        BFormInput,
        BFormGroup,
        BCard,
        BLink,
        BInputGroup,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            userEmail: '',
            password: '',
            cPassword: '',
            status: '',
            isLogged: false,
            error: '',
            passwordFieldType2: 'password',
            token: this.$route.params.token,
        };
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
        },

        passwordToggleIcon2() {
            return this.passwordFieldType2 === 'password' ? 'EyeIcon' : 'EyeOffIcon';
        },
    },
    mounted() {
        this.validateToken();
    },
    methods: {
        async validationForm() {
            this.$refs.loginValidation.validate().then(success => {
                if (success) {
                    this.createPassword();
                }
            });
        },

        async createPassword() {
            this.isLoading(true);
            // eslint-disable-next-line camelcase
            const { password: new_password, cPassword: confirm_password } = this;
            const { status } = await AuthService.recoverPassword({ new_password, confirm_password }, this.token);

            this.isLoading(false);
            if (status === 200) {
                this.modalSuccess('Senha alterada com sucesso');
                this.$router.push('/login');
            }

            this.error = status === 422
                ? 'O email inserido não pertence a uma conta. Verifique seu email e tente novamente.'
                : 'Sua senha está incorreta. Confira-a.';
        },

        async validateToken() {
            const { token } = this.$route.params;
            this.isLoading(true);
            const { status } = await AuthService.validateToken(token);
            this.isLoading(false);

            const errors = {
                200: () => {},
                404: () => this.$router.push('/error-404'),
                406: () => {
                    this.error = 'Token inválido';
                },
            };

            errors[status]();
        },

        togglePassword2Visibility() {
            this.passwordFieldType2 = this.passwordFieldType2 === 'password' ? 'text' : 'password';
        },
    },
};
</script>

    <style scoped lang="scss">
      @import "@core/scss/vue/pages/page-auth.scss";
    </style>
